<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Депозиты - история</h3>
    </div>

    <div>
      <v-data-table
          :items="deposit"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc
          show-expand

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }">  {{item.id}}  </template>
        <template v-slot:item.status="{ item }">  {{item.status}}  </template>

        <template v-slot:item.amount_proposed="{ item }">  {{  `${item.proposed_amount.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'}) }` }}   </template>

        <template v-slot:item.amount_fact="{ item }">  {{  `${item.actual_amount ? item.actual_amount.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'}) : "--"   }` }}   </template>




        <template v-slot:item.network="{ item }">   {{  item.deposit_wallet.cryptonetwork.title }}  </template>
        <template v-slot:item.walletid="{ item }">  {{ item.deposit_wallet ? item.deposit_wallet.id:"--" }}  </template>

        <template v-slot:item.created_at="{ item }"> {{
            item.created_at?new Date(Date.parse(item.created_at)).toLocaleString():""
          }}
        </template>

        <template v-slot:item.check="{ item }"> <a v-if="item.proof" :href="item.proof">Чек</a>  </template>


        <template v-slot:item.nickname="{ item }">  {{item.user? item.user.nickname:""}}  </template>
        <template v-slot:item.userid="{ item }">  {{item.user? item.user.id:""}}  </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length-2">

            <!--span> {{ `Валюта: ${ item.deposit_wallet? item.deposit_wallet.cryptonetwork.crypto.title:"" }`}} </span><br-->
            <span> {{ `Баланс после: ${item.balance_after?item.balance_after.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'}):""}`}} </span><br>
            <span> {{ `Баланс до: ${item.balance_before?item.balance_before.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'}):""}`}} </span><br>
            <span> {{ `ID пользователя: ${ item.user? item.user.id:"" }` }} </span><br>
            <span> {{ `Кошелек зачисления:  ${ item.deposit_wallet? item.deposit_wallet.wallet:"--" }` }} </span><br>
            <span> {{ `Время изменения: ${ item.created_at?new Date(Date.parse(item.created_at)).toLocaleString():""}` }} </span><br>

          </td>
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";
import Snackbar from "../components/controls/Snackbar.vue";
import {fetcher, getHeaders} from "../store";


export default {
  name: 'ExchangeDeposits',

  components: { Snackbar},

  apollo: {
    $subscribe: {
      deposits: {
        query: gql`


 subscription MyQuery {
  deposits(where: {status: {_in: ["Успех","Отмена"]}}, order_by: {id: desc}) {
    id
    actual_amount
    balance_after
    balance_before
    created_at
    status
    updated_at
    proposed_amount
check_id
    proof
    user {
      id
      nickname
    }

    cryptocurrency {
      title
      cryptonetworks {
        title
      }
    }

    deposit_wallet {
      id
      wallet
      description
      cryptonetwork {
        title

      }
    }
  }
}


        `,
        result({data}) {
          this.deposit = data.deposits;
        },
      },
    },
  },


  data: () => ({
    tag: 'test',
    deposit: [],

    headers: [

      { value: "id", text: "ID" },
      { value: "status", text: "Статус" },

      { value: "amount_proposed", text: "Cумма предл." },
      { value: "amount_fact", text: "Cумма зачис." },

      { value: "network", text: "Сеть" },
      { value: "walletid", text: "ID кошелька" },

      { value: "created_at", text: "Время создания" },

      { value: "check", text: "Чек" },
      {value: "check_id", text: "ИД счета"},

     // { value: "actions", text: "Действия" },


      /*     Связь таблицы history и deposit такая же как и у выводов и конвертации. Связь deposit и wallet_networks через deposit.wallet_id = wallet_networks.id
      ID депозита - id из таблицы deposit;
      Статус - status из таблицы deposit;

      Сумма (предложенная) - amount_proposed из таблицы deposit;
      Сумма (зачисленная) - amount_fact из таблицы deposit;

      Сеть - network из таблицы wallet_networks;
      ID кошелька - out_id из таблицы wallet_networks;
      Время создания заявки - created_at из таблицы deposit;
      Чек - check из таблицы deposit;

      ID пользователя - user_id из таблицы history
      Кошелек зачисления - wallet из таблицы wallet_networks;
      Время изменения статуса - updated_at из таблицы deposit;*/

      /*     id
           статус           status
           сумма предл
           сеть
           кошелек          wallet
           время создания   created_at
           чек
           действия         actions
           */


      /*      выпад.
          usdt
          balance_now   баланс после
          balance_old   баланс до
          user { id  пользователя
          кошелек зачисления
          updated_at время изменения
          */

      /* { value: "nickname" , text: "Никнейм" },
         { value: "userid", text: "Юзер ИД" },
         { value: "crypto", text: "Валюта" },
         { value: "amount", text: "Сумма" },
         { value: "commission", text: "Комиссия" },*/

    ],
  }),

  methods: {

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async success( value ) {
      //  /conclusion/success/{conclusion_id}':

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/deposit/success/${value.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async cancel( value , text ) {
      //  /conclusion/cancel/{conclusion_id}':

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({error_text : text})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/deposit/cancel/${value.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    amount_usdt(item){
      return item.conversions[0] ? Math.abs(item.conversions[0].balance_usdt_old - item.conversions[0].balance_usdt_now) : 0
    },

    amount_btc(item){
      return item.conversions[0] ? Math.abs(item.conversions[0].balance_btc_old - item.conversions[0].balance_btc_now) : 0
    },

    money_from(item){
      if (item.conversions){
        if (item.conversions[0].convert_to==="USDT")   //from btc
          return this.amount_btc(item) + " BTC"
        else
          return this.amount_usdt(item) + " USDT"
      }else
        return "0"
    },

    money_to(item){
      if (item.conversions){
        if (item.conversions[0].convert_to==="USDT") //from usdt
          return this.amount_usdt(item) + " USDT"
        else
          return this.amount_btc(item) + " BTC"
      }else
        return "0"
    },

    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

  },

}
</script>
